import React from 'react';
import Header from './Template/Header';
import Menu from './Template/Menu';
import Dashboard from './Template/Dashboard';
import Footer from './Template/Footer';

function App() {
  return (
    <div className="wrapper">
        <Header/>
        <Menu/>
        <Dashboard/>
        <Footer/>
    </div>
  );
}

export default App;
